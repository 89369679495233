<template>
  <div></div>    
</template>

<script>
import store from '../../store'

export default {
  	name: 'CheckSouscription',
    beforeRouteEnter (to, from, next) {
		const {payment_status, entreprise, formule} = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		
		next(vm => {
			if (payment_status != undefined && payment_status.toUpperCase() === 'SUCCESS') {
				let idEntreprise = storage.get('selected_enterprise')
				idEntreprise = entreprise || idEntreprise

				axios.get(`utilisateurs/${storage.get("access_token")}`).then((response) => response.result).then(user => {
					store.dispatch('user', user)
					storage.set('selected_enterprise', idEntreprise)
					window.location.href = vm.$router.options.base
				}).catch(error => {
					store.dispatch("user", {});
					vm.$router.push({name: 'login'})
				})
			}
			else {
				console.log("payment status : ", payment_status)
				vm.$router.push({name: 'subscribe', query: {payment_status}})
			}
		})
	}
}
</script>